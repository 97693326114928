<template>
  <section>
    <issue-center />
    <dialog-covers ref="dlgCover" />
  </section>
</template>

<script>
  export default {
    metaInfo: { title: '关注' },
    components: {
      IssueCenter: () => import('@/pages/sections/home/IssueCenter.vue'),
      DialogCovers: () => import('@/pages/sections/comm/DialogCovers.vue'),
    },
    mixins: [
    ],
    data () {
      return {
        logined: false
      }
    },
    created () {
    },
    methods: {
    }
  }
</script>
